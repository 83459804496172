import React from 'react'
import styled from 'styled-components'
import { Button, Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import PageFullWidth from 'components/Layout/PageFullWidth'
import Link from 'next/link'

const Container = styled.div`
    width:100%;
    max-width: 1300px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    flex-direction: column;
`
const NotFound = () => {
  const { t } = useTranslation()

  return (
    <PageFullWidth>
      <CsContainer>
          <Flex width="100%" maxWidth={["100%",,"550px"]} flexDirection="column" justifyContent="center" alignItems="center" style={{gap:"12px"}}>
              <Flex width="100%" justifyContent='center'>
                <CsHeading>ooops...</CsHeading>
              </Flex>
              <Text color='#D9D9D9' textAlign="center">The page you are looking for could not be found. Please return to the main page to continue your journey. Sorry for the inconvenience!</Text>
              <Flex width="100%" justifyContent="center" zIndex={20}>
                  <Link href="/">
                    <Button>
                      GO TO HOME
                    </Button>
                  </Link>
              </Flex>
          </Flex>
          <Flex width="100%" maxWidth={["100%",,"750px"]} mt={["1rem",,"2rem"]}>
              <ContainerImg src="/images/vndc/404.png?version=1.1" alt="not found" />
          </Flex>
      </CsContainer>
    </PageFullWidth>
  )
}

export default NotFound

const ContainerImg = styled.img`
    width: 100%;
    height: auto;
`
export const CsContainer = styled(Container)`
    width: 100%;
    max-width: 1380px;
    height: 100%;
    min-height: 90vh;
    padding-left:0px;
    padding-right:0px;
    padding-top:1.5rem;
    justify-content: flex-start;
    @media screen and (max-width: 768px) and (min-width: 601px) {
        padding-left:0px;
        padding-right:0px;
        margin-bottom:60px;
    }
    @media screen and (max-width: 600px) {
        padding-left:0px;
        padding-right:0px;
        min-height: 60vh;
    }
`

const CsHeading = styled.h1`
  font-family: Lexend;
  font-size: 92px;
  font-style: normal;
  font-weight: 900;
  line-height: 132%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.background};
  text-shadow:
        1px 1px 0 #D9D9D9,
        -1px -1px 0 #D9D9D9,
        1px -1px 0 #D9D9D9,
        -1px 1px 0 #D9D9D9,
        1px 1px 0 #D9D9D9;
  @media screen and (max-width:600px) {
      font-size: 62px;
  }
`